// Override default variables before the import
$grid-gutter-width: 24px !default;
$spacer: 12px !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.c-axs-tab {
  font-family: sans-serif;
}